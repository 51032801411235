<template>
  <div class="content">
    <div class="row pb-1 pt-0">
      <div class="col-12">
        <card>
          <template slot="header">
            <div v-if="isManager" class="d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button
                    type="cancel"
                    @click="$router.push({ name: 'employeeList' })"
                >{{ $t("goBack") }}</base-button
                >
              </div>
              <div>
                <base-button
                    :disabled="isBtnDisabled"
                    native-type="submit"
                    form="newEmployeeForm"
                    type="success"
                >{{ $t("save") }}</base-button
                >
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id == null && isManager" class="card-title mb-0">
                  {{ $t(`routes.${this.$route.name}`) }}
                </h1>
              </div>
            </div>
          </template>
          <form id="newEmployeeForm" @change="disabledBtn(false)" autocomplete="off" @submit.prevent="manageItem()">
            <div class="col pb-2 mt-0">
              <hr />
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    max="255"
                    v-model="employee.name"
                    :placeholder="$t('name')"
                    :label="`${$t('name')}*`"
                    required
                  ></base-input>
                </div>
                
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <base-input
                    inputType="email"
                    v-model="employee.email"
                    :placeholder="$t('email')"
                    :label="`${$t('email')}*`"
                    required
                  ></base-input>
                  <base-input
                      inputType="phone"
                      v-model="employee.phone"
                      :placeholder="$t('phone')"
                      :label="`${$t('phone')}*`"
                      :disabled="employee.isManager"
                    ></base-input>
                  <small v-if="employee.isManager" class="text-danger">(*){{ $t("phoneManagerWarning") }} </small>
                  <div>
                    <div>
                      <label class="control-label mt-4 mt-md-0">
                        {{ $t("gender") }}*
                      </label>
                    </div>
                    <base-radio
                      inline
                      name="female"
                      class="mt-0"
                      v-model="employee.gender"
                      >{{ $t("female") }}</base-radio
                    >
                    <base-radio
                      inline
                      name="male"
                      class="mt-0"
                      v-model="employee.gender"
                      >{{ $t("male") }}</base-radio
                    >
                    <base-radio
                      inline
                      name="diverse"
                      class="mt-0"
                      v-model="employee.gender"
                      >{{ $t("diverse") }}</base-radio
                    >
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <label class="control-label mt-4 mt-md-0">{{
                    $t("picture")
                  }}</label>
                  <div>
                    <image-upload
                      @change="onImageChange"
                      :src="employee.picture"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="employee"
                    />
                  </div>
                </div>
              </div>
              <div v-if="id == null" class="row mt-4">
                <div class="col-12 col-md-6">
                  <base-input
                    :required="$route.name === 'newEmployee'"
                    inputType="password"
                    v-model="employee.password"
                    @keydown.space.prevent
                    :placeholder="$t('password')"
                    :label="`${$t('password')}*`"
                  ></base-input>
                </div>
                <div class="col-12 col-md-6">
                  <base-input
                    :required="$route.name === 'newEmployee'"
                    inputType="password"
                    v-model="employee.passwordConfirm"
                    @keydown.space.prevent
                    :placeholder="$t('passwordConfirm')"
                    :label="`${$t('passwordConfirm')}*`"
                  ></base-input>
                </div>
              </div>
            </div>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseRadio,
  Card,
  ImageUpload,
} from "@/components/index";
import { mapActions, mapState } from "vuex";

export default {
  name: "EmployeesNew",
  data: () => {
    return {
      id: null,
      route: "/employees",
      isBtnDisabled: true,
      isBtnDisabledPassword: true,
      employee: {
        name: null,
        email: null,
        phone: null,
        gender: "diverse",
        picture: null,
        password: null,
        passwordConfirm: null,
        timetables: [],
      },
      picture: null,
      isModalShow: false,
      newSchedules: [],
      deleteSchedules: [],
      employeeSchedules: [],
    };
  },
  computed: {
    ...mapState(["isManager"]),
  },
  methods: {
    createItem() {
      let employee = new FormData();

      employee.append("name", this.employee.name);
      employee.append("email", this.employee.email);
      employee.append("phone", this.employee.phone);
      employee.append("gender", this.employee.gender);
      if (this.picture) employee.append("media", this.picture);
      return employee;
    },
    createPassword(employee = null) {
      if (
        (this.employee.password === null ||
          this.employee.password === "" ||
          this.employee.password === undefined) &&
        (this.employee.passwordConfirm === null ||
          this.employee.passwordConfirm === "" ||
          this.employee.passwordConfirm === undefined)
      ) {
        if (!this.id)
          this.$toast.error(this.$t("notifications.error.mandatoryPassword"));
        return;
      }

      if (this.employee.password !== this.employee.passwordConfirm) {
        this.$toast.error(
          this.$t("notifications.error.passwordConfirmationNotMatch")
        );
        return;
      }
      if (!employee) employee = new FormData();
      employee.append("password", this.employee.password);
      employee.append("password_confirmation", this.employee.passwordConfirm);
      return employee;
    },
    manageItem() {
      const employee = this.createItem();
      const employeePassword = this.createPassword(employee);
      if (employeePassword) this.storeItem(employeePassword);
    },
    managePassword() {
      const password = this.createPassword();
      if (this.id != null && this.isManager) {
        if (password) this.updateItem(password);
      }
    },
    onImageChange(file) {
      this.picture = file;
    },
    async storeItem(employee) {
      this.disabledBtn(true);
      const response = await this.axios.post(this.route, employee);
      if (response && response.data.status === "success") {
        this.$router.push({ name: "employeeList" });
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    ...mapActions(["setHasChangeForm"])
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    ImageUpload,
    BaseRadio,
  },
};
</script>

<style scoped></style>
